.grid {
	position: relative;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.js .grid::after {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	pointer-events: none;
	background: #252323 url('../images/loading.svg') no-repeat 50% 75px;
	background-size: 60px auto;
	-webkit-transition: opacity 0.3s;
	transition: opacity 0.3s;
}

.js .grid--loaded::after {
	opacity: 0;
}

.grid__item {
	width: 50%;
	padding: .75em;

	@include mobile {
		width: 100%;
	}
}

.grid {
	margin-left: -.75rem;
	margin-right: -.75rem;
}

.grid__item.grid__item-3 {
	width: 33.3333%;	

	@include mobile {
		width: 100%;
		height: auto;
	}
}

.grid--mobile a {
	cursor: default;
	&:hover {
		color: $link;
	}
}

.grid__item--current {
	opacity: 0 !important;
}

.img-wrap {
	display: block;
}

.img-wrap:focus,
.img-wrap:hover {
	outline: none;
}

.img-wrap img {
	display: block;
	max-width: 100%;
}

.preview {
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	pointer-events: none;	
}

.preview::before {
	content: '';
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	background: #1f1d1d;
	-webkit-transition: opacity 0.6s;
	transition: opacity 0.6s;
}

.preview--open {
	pointer-events: auto;
	cursor: pointer;
}

.preview--open::before {
	opacity: 1;
}

.clone {
	position: fixed;
	z-index: 110;
	transition: transform 0.5s;
	-webkit-backface-visibility: hidden;
}

.original {
	position: relative;
	z-index: 120;
	display: block;
	object-fit: contain;
	-webkit-transition: opacity 0.2s;
	transition: opacity 0.2s;
	-webkit-backface-visibility: hidden;
}

.preview--open .animate {
	/* open */
	-webkit-transition: -webkit-transform 0.6s, opacity 0.2s;
	transition: transform 0.6s, opacity 0.2s;
}

.animate {
	/* close */
	-webkit-transition: -webkit-transform 0.3s, opacity 0.2s;
	transition: transform 0.3s, opacity 0.2s;
}

.description--grid {
	display: none;

	@include mobile {
		display: block;
	}
}
.description--grid {
	padding-top:5px;
}
.description--preview {
	position: fixed;
	z-index: 140;
	bottom: 0;
	left: 0;
	width: 100%;
	padding-bottom: 40px;
	text-align: center;
	color: #fff;
	font-size: 1rem;
	opacity: 0;
	// background: linear-gradient(180deg, transparent, #1f1d1d);
	-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
	transition: opacity 0.3s, transform 0.3s;
	-webkit-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0);
}


.preview--open .description--preview {
	opacity: 1;
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}


/* Details */

.details {
	max-width: 100%;
	/* IE 10-11 bug flexbox */
}

.details ul {
	line-height: 1;
	position: relative;
	margin: 0;
	padding: 0;
	list-style: none;
}

.details ul li {
	font-size: 0.5em;
	position: relative;
	display: inline-block;
	margin: 0 1em 0 0;
	padding: 0.15em 0;
	white-space: nowrap;
	opacity: 0;
	color: #9d9d9d;
	-webkit-transition: -webkit-transform 1s, opacity 1s;
	transition: transform 1s, opacity 1s;
	-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
	transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
	-webkit-transform: translate3d(0, 20px, 0);
	transform: translate3d(0, 20px, 0);
}

.preview--open .details ul li {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.preview--open .details ul li:nth-child(1) {
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

.preview--open .details ul li:nth-child(2) {
	-webkit-transition-delay: 0.3s;
	transition-delay: 0.3s;
}

.preview--open .details ul li:nth-child(3) {
	-webkit-transition-delay: 0.4s;
	transition-delay: 0.4s;
}

.preview--open .details ul li:nth-child(4) {
	-webkit-transition-delay: 0.5s;
	transition-delay: 0.5s;
}

.preview--open .details ul li:nth-child(5) {
	-webkit-transition-delay: 0.6s;
	transition-delay: 0.6s;
}

.details ul li:first-child {
	font-weight: bold;
	color: #909090;
}

.icon {
	font-family: 'camera-icons';
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	line-height: 1;
	display: inline-block;
	vertical-align: middle;
	text-transform: none;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	speak: none;
}

.icon + span {
	margin-left: 5px;
	vertical-align: middle;
}

.icon-focal_length:before {
	content: '\e600';
}

.icon-exposure_time:before {
	content: '\e601';
}

.icon-iso:before {
	content: '\e602';
}

.icon-camera:before {
	content: '\e603';
}

.icon-aperture:before {
	content: '\e604';
}

.details .icon {
	margin-right: 5px;
	color: #77d45b;
}


/* Close button */

.action {
	font-size: 4em;
	margin: 0;
	padding: 0;
	cursor: pointer;
	vertical-align: top;
	border: none;
	background: none;
	color: inherit;
}

.action:hover,
.action:focus {
	color: #eeeeee;
	outline: none;
}

.action--close {
	position: fixed;
	z-index: 150;
	top: 0;
	right: 0;
	padding: 10px;
	opacity: 0;
	-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
	transition: opacity 0.3s, transform 0.3s;
	-webkit-transform: scale3d(0.6, 0.6, 1);
	transform: scale3d(0.6, 0.6, 1);
}

.preview--image-loaded .action--close {
	opacity: 1;
	-webkit-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1);
}


@media screen and (max-width: 40em) {
	.description--preview h3 {
		font-size: 0.5em;
	}
	.description--preview p,
	.details {
		display: none;
	}
}