//  ----- Utils -----
.is-desktop-narrow {
  @include desktop {
    max-width: 100%;
    width: 1200px;
    padding-left: 200px;
  }
}

.is-show-fullhd {
  display: none;

  @include fullhd {
    display: flex;
  }
}

.invisible-mobile {
  @include mobile {
    opacity: 0;
    visibility: hidden;
    display:none;
  }
}
.navbar-mobile .invisible-mobile{
  display:block;
}

.clear {
  @include clearfix;
}

// ----- Basics ------

h1,
h2 {
  font-family: $h1-font-family;
  font-size: $h1-font-size;
  letter-spacing: $h1-letter-spacing;
  margin: $h1-margin;
  text-transform: uppercase;
}

body {
  height: 100%;
  font-size: 0.8rem;
  line-height: 1.25rem;
}

.wrapper {
  width: 1600px;
  height: 100%;
  max-width: 100%;
  padding: 30px 25px;
  margin: 0 auto;
  position: relative;
}

main {
  margin-top: 60px;
  margin-bottom: 100px;
}

// ----- Homepage ------
.page-works {
  padding-left: 0;
  font-family: $family-font-front-page;

  @include desktop {
    padding-left: 235px;
  }
}

.works-menu {
  font-size: 0.8rem;
  line-height: 1.25rem;
  text-transform: uppercase;
}

.works-menu__item {
  position: relative;

  &:nth-child(even) {
    padding-left: 25px;
  }
}

.works-menu__item a {
  font-size: $works-font-size;
  line-height: $works-line-height;
  color: $works-item-color;

  &:hover,
  &.is-active {
    color: $works-item-hover-color;
  }

  @include mobile {
    font-size: $work-mobile-font-size;
  }

  @media only screen and (max-width: 460px) {
    font-size: 2.1rem;
  }
}

.works-menu__sub {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  top: 0;
  z-index: 2;
  margin-left: 500px;

  @include mobile {
    position: relative;
    display: none;
    margin: 20px 0 30px 20px;
  }
}

.works-menu__sub-item a {
  color: $works-sub-item-color;
  font-size: 1.555rem;
  line-height: 1em;
}

.works-menu__sub_move {
  opacity: 1;
  visibility: visible;
  margin-left: 540px;
  transition: margin-left 1s, opacity 1s;
  -moz-transition: margin-left 1s, opacity 1s;
  -webkit-transition: margin-left 1s, opacity 1s;
  -o-transition: margin-left 1s, opacity 1s;

  @include mobile {
    margin-left: 30px;
    display: block;
    transition: none;
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: none;
  }
}

// Page: Photos
.work {
  margin-bottom: 65px;
  max-width: 920px;
}

.work-capture {
  font-size: 0.741rem;
  line-height: 0.9rem;
  padding-top: 17px;
  text-transform: uppercase;
}

.columns-first {
  margin-bottom: 65px !important;
}

// Page: Galleries, Contacts
.page-galleries h2 {
  font-size: 1.15rem;
}

.year,
.place {
  display: inline-block;
  vertical-align: top;
}

.year {
  width: 10%;
}

.place {
  width: 88%;
}

.content {
  font-size: 0.8rem;
  line-height: 1.25rem;

  &:not(:last-child) {
    margin-bottom: 40px;
  }
}

.content-link {
  font-size: inherit;
  line-height: inherit;
  border-bottom: 1px dotted $text-color;
  color: $text-color;
}

// ----- Slider ------
.border {
  border: 1px solid grey;
  width: 100%;
}

/*! http://responsiveslides.com v1.55 by @viljamis */

.rslides {
  position: relative;
  list-style: none;
  overflow: hidden;
  width: 100%;
  padding: 0;
  margin: 0;

  @media (min-width: 769px) and (max-width: 1328px) {
    width: 505px;
  }
}

.rslides li {
  -webkit-backface-visibility: hidden;
  position: absolute;
  display: none;
  width: 100%;
  left: 0;
  top: 0;
}

.rslides li:first-child {
  position: relative;
  display: block;
  float: left;
}

.rslides img {
  display: block;
  height: auto;
  float: left;
  width: 100%;
  border: 0;
}

.contact-form {
  max-width: 400px;
}

.contact-form__label input,
.contact-form__label textarea {
  outline: none;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid $text-color;
  border-radius: 0.15rem;
  background-color: $body-bg-color;
  margin-bottom: 1rem;
  resize: vertical;
  color: $text-color;
}

.contact-form__label input::placeholder {
  color: #71717a;
}

.contact-form__button {
  cursor: pointer;
  padding: 0.5rem 2rem;
  display: block;
  margin-left: auto;
  border: 1px solid $text-color;
  border-radius: 0.15rem;
  background-color: $text-color;

  &:hover {
    --tw-shadow-color: #5a5a5d;
    --tw-shadow: var(--tw-shadow-colored);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
      0 2px 4px -2px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}
