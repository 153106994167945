.navbar {
    display: flex;
    font-family: $family-font-front-page;
}
.navbar-top-menu {
    display: block;
    padding: 0;
    box-shadow: none;
    > * {
        display: inline-block;
        vertical-align: baseline;
        padding: 0px;
    }
    .navbar-item:not(:last-child) {
        padding-right: 35px;
    }
    .navbar-item:nth-child(1) {
        font-size: 2.413rem;
    }
    .navbar-item:nth-child(2) {
        font-size: 1.804rem;
    }
    .navbar-item:nth-child(3) {
        font-size: 1.4333rem;
    }
    .navbar-item:nth-child(4) {
        font-size: 1.2853rem;
    }        
}
.navbar-brand {   
    min-width: 237px; 
    font-size: 2.96rem;
    line-height: 1em;   
    a.brand {
        color: $logo-link-color;        
        opacity: 0;
        width: 0;
        visibility: hidden;
        transition: all 1s;
        @include tablet {
            margin-right: 100px;
            width: auto;
            opacity: 1;
            visibility: visible;
        }
        @media only screen and (min-width: $tablet) and (max-width: 900px) {
            margin-right: 15px;
            width: auto;
        }   
    }
    a.brand-small {
        color: $logo-link-color;
        position: fixed;
        margin-top: -10px;
        width: auto;
        opacity: 1;
        visibility: visible;
        transition: all 1s;      
        @include tablet {
            width:0;
            margin-top: -35px;
            opacity: 0;
            visibility: hidden;
        }
    }   
    &.scroll {
        a.brand  {
            opacity: 0;
            visibility: hidden;
        }
        a.brand-small {
            opacity: 1;
            visibility: visible;
            margin-top: -15px;
        }
    }          
}

//
// navbar mobile menu
//

@include mobile {
    .navbar-mobile {
        .navbar-menu {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 1;
            visibility: visible;
            z-index: 3;
            background: $mobile-menu-bg-color;
            transition: opacity 1s;
            padding-top: 130px !important;
            padding-left: 30px;
            .navbar-item {
                display: block;
                margin-bottom: 30px;
                color: $text-color;
                &.is-active, &:hover {
                    color: $link-hover;
                }
            }
        }
        .navbar-brand .brand-small {
            color: #201f28;
            z-index: 4;
        }
    }
    .navbar-burger {
        position: fixed;
        right: 23px;
        z-index: 4;
        span {
            width: 45px;
            height: 3px;
            background: $text-color;
            left: 0;
            transition: all .7s;
            &:nth-child(1) {
                top: 6px;
            }
            &:nth-child(2) {
                top: 17px;
            }
        }
        &.is-active {
            span {
                &:nth-child(1) {
                    transform: translateY(5px) rotate(45deg);
                }
                &:nth-child(2) {
                    transform: translateY(-5px) rotate(-45deg);
                    opacity: 1;
                }
            }
        }
        &:hover {
            background-color: transparent;
        }
    }
}